import React from 'react';
import { Link } from 'react-router-dom';

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Група Inermediate</h4>
                    <p>Володіння англійською мовою Intermediate — це середнє знання мови або ступінь В1 за системою CEFR. Студенти цього рівня вільно розмовляють та пишуть англійською мовою, однаково добре використовують і побутові, і спеціальні (професійні) слова та фрази.</p>
                    <p>Знання англійської на рівні Intermediate дозволяє вступати до закордонних вузів  та розпочинати підготовку до міжнародних іспитів. Здавши їх, підвищується шанс працювати за кордоном: багато компаній потрібні співробітники з вільним володінням англійською.</p>
                    <h3>Теми</h3>
                    <ul className="review-list">
                        <li>Усі часи дієслів (Present, Past, Future)</li>
                        <li>Модальні дієслова</li>
                        <li>Особливості використання інфінітиву та герундію</li>
                        <li>Ступені порівняння прикметників та виключення</li>
                        <li>Певний та невизначений артикль (a/an, the)</li>
                        <li>Використання слів, що визначають кількість (some/any, a few/a little, much/many/a lot of)</li>
                        <li>Три типи умовних пропозицій (1st, 2nd and 3rd Conditionals)</li>
                        <li>Придаткові визначальні та їх типи (defining/non-defining relative clauses)</li>
                        <li>Indirect speech</li>
                        <li>Дійсна та пасивна застава</li>
                        <li>Використання фразових дієслів</li>
                    </ul>
                    <h3>Лексика</h3>
                    <ul className="review-list">
                        <li>Сім'я та особистість</li>
                        <li>Зовнішній вигляд та характер</li>
                        <li>Робота та професія</li>
                        <li>Бізнес та гроші</li>
                        <li>Транспорт та подорожі</li>
                        <li>Природа, довкілля, екологія</li>
                        <li>ЗМІ</li>
                        <li>Кіно</li>
                        <li>Мистецтво</li>
                        <li>Похід по магазинам</li>
                        <li>Стиль життя</li>
                        <li>Проблеми та успіх</li>
                        <li>Злочинність та злочинці</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;