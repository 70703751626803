import React from 'react';
import { Link } from 'react-router-dom';
import TeamDetailsProgressBar from '../../components/Elements/Progress/TeamDetailsProgressBar';
// Breadcrumbs Bg
// import teamImg1 from '../../assets/img/team/9.jpg';

const TeamSingleMain = props => {
  const { name, position, pearsonImg, description } = props;
    return (
        <div className="profile-section pt-100 pb-90 md-pt-80 md-pb-70">
            <div className="container">
                <div className="row clearfix">
                    <div className="image-column col-lg-5 md-mb-50">
                        <div className="inner-column mb-50 md-mb-0">
                            <div className="image">
                                <img src={pearsonImg} alt="images" />
                            </div>
                            <div className="team-content text-center">
                                <h3>{name}</h3>
                                <div className="text">{position}</div>
                            </div>
                        </div>
                    </div>
                    <div className="content-column col-lg-7 pl-60 pt-50 md-pl-14 md-pt-0">
                        <div className="inner-column">
                            <h2>{name}</h2>
                            <h4>{position}</h4>
                            <h5>Про мене</h5>
                            <p>{description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamSingleMain;