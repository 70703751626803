
import React from 'react';
import { Helmet } from 'react-helmet';
import BlogMain from './BlogMain';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import Newsletter from '../../components/Common/Newsletter';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SearchModal from '../../components/Layout/Header/SearchModal';

// Image
import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/cat_logo.png';
import footerLogo from '../../assets/img/logo/cat_logo.png';

import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

const Blog = () => {
    return (
        <React.Fragment>
            <Helmet>
              <title>"Wonder блог"</title>
              <meta name="description" content="Приєднуйтеся до Школи Іноземних Мов Wonderland і навчайтеся іноземним мовам з нами. Ми пропонуємо широкий вибір курсів іноземних мов, що допоможуть вам розвивати свої навички у вивченні іноземних мов. З нами ви зможете швидко і зручно навчитися іноземній мові, що відкриє для вас нові можливості у житті і кар'єрі. Зареєструйтеся зараз і розпочніть свій шлях до володіння іноземною мовою з нами!" />
              <meta property="og:title" content="Wonder блог" />
              <meta property="og:image" content="/preview.jpg" />
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='blog'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='perfectschool777@gmail.com'
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Блог"
                pageName="блог"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            {/* Blog Main */}
            <BlogMain />
            {/* Blog Main End */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}


export default Blog;

