import React from 'react';
import SinglePostSidebar from './SinglePostSidebar';
import SinglePostSix from '../../components/Blog/SinglePostSix';

import blogImg1 from '../../assets/img/blog/inner/1.jpg'
import blogImg2 from '../../assets/img/blog/inner/2.jpg'
import blogImg3 from '../../assets/img/blog/inner/3.jpg'
import blogImg4 from '../../assets/img/blog/inner/4.jpg'
import blogImg5 from '../../assets/img/blog/inner/5.jpg'
import blogImg6 from '../../assets/img/blog/inner/6.jpg'
import blogImg7 from '../../assets/img/blog/inner/7.jpg'
import blogImg8 from '../../assets/img/blog/inner/8.jpg'

import post1Image from '../../assets/img/blog/inner/b1.jpeg'
import post2Image from '../../assets/img/blog/inner/b2.jpeg'
import post3Image from '../../assets/img/blog/inner/b3.jpeg'
import post4Image from '../../assets/img/blog/inner/b4.jpeg'
import post5Image from '../../assets/img/blog/inner/b5.jpeg'
import post6Image from '../../assets/img/blog/inner/b6.jpeg'
import post7Image from '../../assets/img/blog/inner/b7.jpeg'
import post8Image from '../../assets/img/blog/inner/b8.jpeg'

const BlogMain = () => {

    return (
        <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-70 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 order-last">
                        <div className="widget-area">
                            <SinglePostSidebar />
                        </div>
                    </div>

                    <div className="col-lg-8 pr-50 md-pr-16">
                        <div className='row'>
                            <div className='col-lg-12 mb-70 md-mb-50'>
                                <SinglePostSix
                                    blogImage={post1Image}
                                    blogAuthor='Admin'
                                    blogCategory='University'
                                    blogPublishedDate='January 21, 2022'
                                    blogTitle='Власне, навіщо?'
                                    linkTo="/blog/why"
                                    postImage={post1Image}
                                    blogDesc='У кожного школяра під час навчання рано чи пізно виникає типове запитання: «А як мені знання знадобляться у житті?». Зазвичай подібні думки мучать юні уми на уроках математики та геометрії, але й англійська мова не стала винятком....'
                                    blogButtonClass='blog-button'
                                    blogButtonText='Читати більше'
                                />
                            </div>
                            <div className='col-lg-12 mb-70 md-mb-50'>
                                <SinglePostSix
                                    blogImage={post2Image}
                                    blogAuthor='Admin'
                                    blogCategory='University'
                                    blogPublishedDate='January 21, 2022'
                                    linkTo="/blog/how-long-does-it-take-to-master-one-level-of-english"
                                    blogTitle='Скільки часу потрібно для освоєння одного рівня англійської мови?'
                                    blogDesc='Для початку перерахуємо всі рівні, які існують!'
                                    blogButtonClass='blog-button'
                                    blogButtonText='Читати більше'
                                />
                            </div>
                            <div className='col-lg-12 mb-70 md-mb-50'>
                                <SinglePostSix
                                    blogImage={post3Image}
                                    blogAuthor='Admin'
                                    blogCategory='University'
                                    blogPublishedDate='January 21, 2022'
                                    linkTo="/blog/british-accent-from-3-years-old"
                                    blogTitle='Британський акцент з 3 років?'
                                    blogDesc='Звичайно, все можливе! Скажімо, навіть, ніж раніше, аж з дитинства, дитина почне чути британський акцент... '
                                    blogButtonClass='blog-button'
                                    blogButtonText='Читати більше'
                                />
                            </div>
                            <div className='col-lg-12 mb-70 md-mb-50'>
                                <SinglePostSix
                                    blogImage={post4Image}
                                    blogAuthor='Admin'
                                    blogCategory='University'
                                    blogPublishedDate='January 21, 2022'
                                    linkTo="/blog/why-read-to-children-in-english-from-childhood"
                                    blogTitle='Навіщо читати діткам на інглиші з дитинства?'
                                    blogDesc="Дитина навчається, запам'ятовує слова і наслідує звуки до трьох років набагато краще, ніж..."
                                    blogButtonClass='blog-button'
                                    blogButtonText='Читати більше'
                                />
                            </div>
                            <div className='col-lg-12 mb-70 md-mb-50'>
                                <SinglePostSix
                                    blogImage={post5Image}
                                    blogAuthor='Admin'
                                    blogCategory='University'
                                    blogPublishedDate='January 21, 2022'
                                    linkTo="/blog/adult-education"
                                    blogTitle='Освіта дорослих'
                                    blogDesc='Більшість людей у школі вивчали іноземні мови, і переважно це англійська. Доросла людина звертається до нас за допомогою у вивченні англійської мови з різних причин...'
                                    blogButtonClass='blog-button'
                                    blogButtonText='Читати більше'
                                />
                            </div>
                            <div className='col-lg-12 mb-70 md-mb-50'>
                                <SinglePostSix
                                    blogImage={post6Image}
                                    blogAuthor='Admin'
                                    blogCategory='University'
                                    blogPublishedDate='January 21, 2022'
                                    linkTo="/blog/how-to-teach-older-people-for-you"
                                    blogTitle='Як вчити людей старших за тебе?'
                                    blogDesc='Перед тим, як відповісти на це питання, для початку варто зрозуміти...'
                                    blogButtonClass='blog-button'
                                    blogButtonText='Читати більше'
                                />
                            </div>
                            <div className='col-lg-12 mb-70 md-mb-50'>
                                <SinglePostSix
                                    blogImage={post7Image}
                                    blogAuthor='Admin'
                                    blogCategory='University'
                                    blogPublishedDate='January 21, 2022'
                                    linkTo="/blog/mom-teacher"
                                    blogTitle='Мама-училка? Так, easy!'
                                    blogDesc='Починаючи з чудової новини про вагітність, усі працюючі дівчата починають замислюватися про те...'
                                    blogButtonClass='blog-button'
                                    blogButtonText='Читати більше'
                                />
                            </div>
                            <div className='col-lg-12'>
                                <SinglePostSix
                                    blogImage={post8Image}
                                    blogAuthor='Admin'
                                    blogCategory='University'
                                    blogPublishedDate='January 21, 2022'
                                    linkTo="/blog/zoom-skype-viber-hangouts"
                                    blogTitle='Zoom, Skype, Viber, Hangouts... Страшно, так?'
                                    blogDesc='Дистанційне навчання розділило своїх учасників на два великі та активно протиборчі табори.'
                                    blogButtonClass='blog-button'
                                    blogButtonText='Читати більше'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogMain;