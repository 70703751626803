import React from 'react';
import { Helmet } from 'react-helmet';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import Newsletter from '../../components/Common/Newsletter';
import ContactMain from './ContactMain';

// Image
import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/cat_logo.png';
import footerLogo from '../../assets/img/logo/cat_logo.png';


const Contact = () => {
    return (
        <React.Fragment>
            <OffWrap />
            {/* Header */}
            <Helmet>
              <title>"Контакти Wonderland"</title>
              <meta name="description" content="Приєднуйтеся до Школи Іноземних Мов Wonderland і навчайтеся іноземним мовам з нами. Ми пропонуємо широкий вибір курсів іноземних мов, що допоможуть вам розвивати свої навички у вивченні іноземних мов. З нами ви зможете швидко і зручно навчитися іноземній мові, що відкриє для вас нові можливості у житті і кар'єрі. Зареєструйтеся зараз і розпочніть свій шлях до володіння іноземною мовою з нами!" />
              <meta property="og:title" content="Контакти Wonderland" />
              <meta property="og:image" content="/preview.jpg" />
            </Helmet>
            <Header
                parentMenu='contact'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBarClass="topbar-area home8-topbar"
                emailAddress='perfectschool777@gmail.com'
            />
            {/* Header */}

            {/* ContactMain Section Start */}
            <ContactMain />
            {/* ContactMain Section End */}

            {/* Footer */}
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />
            {/* Footer */}

            {/* SearchModal */}
            <SearchModal />
            {/* SearchModal */}

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}


export default Contact;