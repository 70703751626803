import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BlogDetails from './BlogDetails';
import SinglePostSidebar from './SinglePostSidebar';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import Newsletter from '../../components/Common/Newsletter';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SearchModal from '../../components/Layout/Header/SearchModal';

// Image
import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/cat_logo.png';
import footerLogo from '../../assets/img/logo/cat_logo.png';

import postImage from '../../assets/img/blog/inner/1.jpg';
import post1Image from '../../assets/img/blog/inner/b4.jpeg'

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/breadcrumbs/5.jpg';

const SinglePostRightSidebar = (props) => {

    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='blog'
                secondParentMenu='blogSingle'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='perfectschool777@gmail.com'
            />

            <SiteBreadcrumb
                pageTitle="Навіщо читати діткам на інглиші з дитинства?"
                pageName="Навіщо читати діткам на інглиші з дитинства?"
                breadcrumbsImg={bannerbg}
            />

            {/* Blog Details Start */}
            <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 order-last">
                            <div className="widget-area">
                                <SinglePostSidebar />
                            </div>
                        </div>
                        <div className="col-lg-8 pr-50 md-pr-14">
                          <div className="blog-deatails">
                            <div className="bs-img">
                                <img
                                    src={post1Image}
                                    alt="Post Image"
                                />
                            </div>
                            <div className="blog-full">
                                <div className="blog-desc">
                                    <p>
                                      Дитина навчається, запам'ятовує слова і наслідує звуки до трьох років набагато краще, ніж діти старшого віку. А період казок і «Мамочка, ну почитай, будь ласка»)) припадає саме на цей період життя. Щоб дитина засвоювала англійську природним чином, вона повинна постійно чути її і мати можливість спілкуватися на ньому. Існує чудовий метод TPR (Total Physical Response) метод повного фізичного реагування, націлений на сприйняття мови через органи почуттів та підходить для кінестетиків, аудіалів та візуалів. У дітей, якраз, і переважає наочно-образне мислення, щоб запам'ятати нове слово дитині потрібно співвіднести це слово з його образом-предметом, картинкою або жестом. У цьому чудово допоможе інсценування казок, які ви читаєте з малюком.
                                    </p>
                                </div>
                                <div className="blog-desc">
                                    <p>
                                      Щоб покращити процес сприйняття та запам'ятовування:
                                    </p>
                                </div>
                                <ul className="unorder-list mb-20">
                                    <li>Пропонуйте зобразити те, про що читаєте;</li>
                                    <li>Запитуйте , наприклад: "Do you see an elephant?"</li>
                                    <li>Просіть показувати предмети, персонажів, видавати звуки тварин, що зустрічаються у книзі;</li>
                                    <li>Показуйте зображення під час читання;</li>
                                    <li>Якщо діти ставлять запитання, не лінуйтеся терпляче відповідати. Це показує зацікавленість дитини.</li>
                                </ul>
                                <div className="blog-desc">
                                    <p>
                                      Тому, дорогі батьки, читайте при кожній нагоді, поринайте у світ казок і пригод! Головне -регулярно) І результат не змусить себе чекати!
                                    </p>
                                </div>
                            </div>
                        </div>
                            {/* <BlogDetails /> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* Blog Details End */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}


export default SinglePostRightSidebar;



