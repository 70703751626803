import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';

import footerLogo1 from '../../../assets/img/logo/cat_logo.png';
import postImg1 from '../../../assets/img/blog/inner/b1.jpeg';
import postImg2 from '../../../assets/img/blog/inner/b3.jpeg';

const Footer = (props) => {
    const { footerLogo, footerClass, footerTopClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className={`footer-top ${footerTopClass}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <div className="footer-logo mb-30">
                                <Link to="/" as="/">
                                    <img src={footerLogo1} alt="Logo" />
                                </Link>
                            </div>
                            <ul className="footer_social">
                                <li>
                                    <a href="https://www.instagram.com/wonderland_eng/?igshid=YmMyMTA2M2Y%3D" target="_blank">
                                        <i className="fa fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <h3 className="widget-title">Контакти</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc"><a href="tel:+380662141593">(+38)066-214-15-93</a></div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc"><a href="mailto:perfectschool777@gmail.com">perfectschool777@gmail.com</a></div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 pl-50 md-pl-14 footer-widget md-mb-50">
                            <h3 className="widget-title">Наші курси</h3>
                            <ul className="site-map">
                                <li><Link to="/course">Курси</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget">
                            <h3 className="widget-title">Нещодавні пости</h3>
                            <div className="recent-post mb-20">
                                <div className="post-img">
                                    <img src={postImg1} alt="blog image" />
                                </div>
                                <div className="post-item">
                                    <div className="post-desc">
                                        <Link to="/blog/why">Власне, навіщо?</Link>
                                    </div>
                                    {/* <span className="post-date">
                                        <i className="fa fa-calendar-check-o"></i>
                                        October 15, 2020
                                    </span> */}
                                </div>
                            </div>
                            <div className="recent-post mb-20">
                                <div className="post-img">
                                    <img src={postImg2} alt="blog image" />
                                </div>
                                <div className="post-item">
                                    <div className="post-desc">
                                        <Link to="/blog/british-accent-from-3-years-old">Британський акцент з 3 років?</Link>
                                    </div>
                                    {/* <span className="post-date">
                                        <i className="fa fa-calendar-check-o"></i>
                                        April 25, 2020
                                    </span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBottom />
        </footer>
    );
}

export default Footer;