import React from 'react';
import { Link } from 'react-router-dom';

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Група Starters 1</h4>
                    <p>Група для дітей віком 6-7 років,які тільки починають вивчати англійську,а також для учнів 2 класу, які з різних причин мають труднощі у засвоєнні шкільної програми.</p>
                    <p>Підручник English world 1 розрахований на 2 роки навчання, отже у цій групі діти проходять перші 6 юнітів,а перед цим спеціальний посібник Starter pack, де вивчають кольори, числа від 1-10, геометричні фігури, тварин,та букви.</p>
                    <p>Учні паралельно навчаються читати за допомогою phonics,а не лише алфавіту та писати букви,слова і невеличкі речення.</p>
                    <p>Заняття проходять в інтерактивній формі з постійною зміною виду діяльності, з урахуванням психологічних особливостей дітей цього віку.</p>
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;