import React, { Component, Fragment } from 'react';

const FeatureSingle = (props) => {
    return (
        <div className={props.itemClass}>
          {props?.languages ? (
            <div className="icon-part--inside">
              {props?.languages.map((item, idx) => (
                <div className="icon-part--inside-item" key={idx}>
                  <img
                      src={item.img}
                      alt={item.title}
                  />
                  <span>{item.title}</span>
                </div>
              ))}
            </div>
          ) : (
            <Fragment>
              <div className="icon-part">
                  <img
                      src={props.itemImg}
                      alt={props.itemTitle}
                  />
              </div>
              <div className="content-part">
                  <h4 className="title">{props.itemTitle}</h4>
                  <p className="dese">{props.itemDesc}</p>
              </div>
            </Fragment>
          )}
        </div>
    )
}

export default FeatureSingle