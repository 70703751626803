import React from 'react';
import { Link } from 'react-router-dom';

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Група з нуля</h4>
                    <p>Цей курс англійської мови надає основи лексики та граматики, допоможе вивчити базову термінологію потрібну для роботи та сімейного життя. За декілька місяців ви навчитеся правильно будувати речення, а розмовляти почнете вже на першому занятті. Зможете вести діалог на базові теми. Ви закохаєтесь в нашу атмосферу та будете завжди замотивовані. Навчання відбувається за підручниками English File та Solutions, а також доповнюється аутентичними матеріалами і сучасними digital завданнями.</p>
                    <h3>Теми</h3>
                    <ul className="review-list">
                        <li>Сountries</li>
                        <li>Daily routine</li>
                        <li>Family</li>
                        <li>Weather</li>
                        <li>City holidays</li>
                        <li>Common adjectives and adverbs</li>
                        <li>Food and drinks</li>
                        <li>Places and buildings</li>
                    </ul>
                    <h3>Граматика</h3>
                    <ul className="review-list">
                        <li>Irregular verbs</li>
                        <li>Present tenses</li>
                        <li>Modal verbs,</li>
                        <li>Word order</li>
                        <li>Object pronouns</li>
                        <li>Countable and countable nouns</li>
                        <li>Plural nouns</li>
                        <li>Articles</li>
                        <li>To be going to</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;