import React from 'react';
import { Link } from 'react-router-dom';

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Група Advanced</h4>
                    <p>Advanced називають просунутим рівнем володіння мовою. Рівень знання англійської Advanced (C1) займає п'яте, передостаннє місце за загальноєвропейською шкалою мовної компетенції CEFR. Основна ознака просунутого рівня володіння мовою - жива, спонтанна, швидка мова у повсякденній та професійній сферах.</p>
                    <p>Англійська мова на рівні Advanced – це маса можливостей та практично повністю відкритий світ англомовної культури: читання оригінальних творів, перегляд англомовних передач, ведення листування та, найголовніше, живе та вільне спілкування з носіями мови.</p>
                    <h3>Граматика</h3>
                    <ul className="review-list">
                        <li>Всі часи  в активній та пасивній заставі (Active/Passive Voice)</li>
                        <li>Усі модальні дієсліви (Modal verbs)</li>
                        <li>Безособові конструкції (Impersonal constructions)</li>
                        <li>Складові іменники (Compound nouns)</li>
                        <li>Умовні речення змішаних типів (Mixed Conditionals)</li>
                        <li>Інверсія (Inversion)</li>
                        <li>Розщеплені пропозиції (Cleft Sentences)</li>
                        <li>Дискурсійні маркери (Discourse markers)</li>
                        <li>Еліпсис (Ellipsis)</li>
                    </ul>
                    <h3>Лексика</h3>
                    <ul className="review-list">
                        <li>Особистість (Personality)</li>
                        <li>Звуки та людський голос (Sounds and human voice)</li>
                        <li>Робота та робочі місця (Work and Working place)</li>
                        <li>Емоції та почуття (Emotions and Feelings)</li>
                        <li>Здоров'я та спорт (Health and Sport)</li>
                        <li>Політика та закон (Politics and Law)</li>
                        <li>Технології та прогрес (Technology and Progress)</li>
                        <li>Освіта та способи навчання (Education and Ways of learning)</li>
                        <li>Довкілля (Environment)</li>
                        <li>Медицина (Medicine)</li>
                        <li>Конфлікти та війни (Conflict and Warfare)</li>
                        <li>Подорожі та вільний час (Travelling and Leisure time)</li>
                        <li>Книги та фільми (Books and Films)</li>
                        <li>Приготування їжі (Preparing food)</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;