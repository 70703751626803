import React from 'react';
import { Link } from 'react-router-dom';
import SingleTeam from '../../../components/Team/SingleTeam';

// Image
import teamimg2 from '../../../assets/img/team/Sofiia.jpg';

const InstructorPart = () => {

    return (
        <div className="content pt-30 pb-30 pl-30 pr-30 white-bg">
            <h3 className="instructor-title">Викладачі</h3>
            <div className="row rs-team style1 orange-color transparent-bg clearfix">
                <div className="col-lg-6 col-md-6 col-sm-12 sm-mb-30">
                    <SingleTeam
                        teamClass="team-item"
                        Image={teamimg2}
                        linkTo="/team/sofiia-sokolnikova"
                        Title="Софія Сокольнікова"
                        Designation="Викладач англійської"
                    />
                </div>
            </div>  
        </div>
    );
}

export default InstructorPart;