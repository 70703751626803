import React from 'react';
import { Link } from 'react-router-dom';

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Група Flyers</h4>
                    <p>Група для дітей 9-11 років, які продовжують вивчення англійської мови. Навчання відбувається за підручником English world 3.</p>
                    <p>Діти вчаться орієнтуватися в використанні минулого часу, ступенів порівняння прикметників, знайомляться з неправильними дієсловами,та конструкцією to be  going to для описання подій майбутнього часу. Також вивчають присвійні та питальні займенники, різницю між some та any.</p>
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;