import React from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import Newsletter from '../../components/Common/Newsletter';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import TeamSingleMain from './TeamSingleMain';

// Image
import Logo from '../../assets/img/logo/cat_logo.png';
import footerLogo from '../../assets/img/logo/cat_logo.png';

import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

import teamimg1 from '../../assets/img/cta/Angie.png';

const TeamSingle = () => {
    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='pages'
                secondParentMenu='team'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='perfectschool777@gmail.com'
                // Location='374 William S Canning Blvd, MA 2721, USA'
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Анжеліка Гібська"
                pageName="Команда"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            {/* TeamDetails Start */}
            <TeamSingleMain
                name="Анжеліка Гібська"
                position="Засновниця та викладач"
                pearsonImg={teamimg1}
                description={`Знайомтесь, наш керівник, ідейний натхненник та викладач із 13-річним досвідом.
                  Своїм навчальним девізом вважає: немає талановитих студентів, є нерозкриті.
                  
                  Рівень володіння мовою С1, IELTS 8.0, 5000+ успішних кейсів у студентів, автор курсу для викладачів ”Proкачай себе як tutor”, авторського проекту Simple English, Weekend .
                  
                  При створенні Wonderland, головним чином хотіла втілити мрію та створити чарівний простір, де пануватиме дитячий сміх, якісні та сучасні матеріали приведуть до бажаних результатів, а також на уроки учні бігтимуть стрімголов, бо тут не наругають, а допоможуть заговорити з першого заняття.
                  
                  Викладає методом Dogme або Unplugged Teaching, де процес навчання будується навколо студента, а не підручника. Велика фанатка підготовки до міжнародних іспитів та якісних проміжних результатів.
                  
                  На даний момент Анжеліка знаходиться в Канаді, де щодня спілкується з носіями та ділиться в інстаграмі школи найсвіжішими виразами з англомовного середовища.
                  
                  Всі уроки нашого керівника sold out, можливість записатися до неї на заняття є лише влітку 2023. Консультації для викладачів обговорюються в особистому порядку.`}
            />
            {/* TeamDetails End */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}


export default TeamSingle;