import React from 'react';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SectionTitle from '../../components/Common/SectionTitle';
import ContactForm from '../../components/Contact/ContactForm';
import ContactInfo from '../../components/Contact/ContactInfo';

import bannerbg from '../../assets/img/breadcrumbs/inner13.jpg';


const ContactMain = () => {

    return (
        <React.Fragment>

            {/* SiteBreadcrumb */}
            <SiteBreadcrumb
                pageTitle="Контакти"
                pageName="Контакти"
                breadcrumbsImg={bannerbg}
            />
            {/* SiteBreadcrumb */}

            {/* Contact Section Start */}
            <div className="rs-contact style1 event-bg pt-110 md-pt-80 pb-100 md-pb-80">
                <div className="container pb-66 md-pb-46">
                    <div className="row gutter-35">
                        <div className="col-md-6">
                            <ContactInfo
                                boxClass="sm-mb-30"
                                title="Email адреса"
                                iconClass="flaticon-email"
                                email="perfectschool777@gmail.com"
                            />
                        </div>
                        <div className="col-md-6">
                            <ContactInfo
                                boxClass=""
                                title="Номер телефону"
                                iconClass="flaticon-phone"
                                phone="+380662141593"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact Section End */}
        </React.Fragment>

    );
}


export default ContactMain;