import React from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import Newsletter from '../../components/Common/Newsletter';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import TeamSingleMain from './TeamSingleMain';

// Image
import Logo from '../../assets/img/logo/cat_logo.png';
import footerLogo from '../../assets/img/logo/cat_logo.png';

import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

import teamimg6 from '../../assets/img/team/JuliaV.jpg';

const TeamSingle = () => {
    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='pages'
                secondParentMenu='team'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='perfectschool777@gmail.com'
                // Location='374 William S Canning Blvd, MA 2721, USA'
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Юлія Волощук"
                pageName="Команда"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            {/* TeamDetails Start */}
            <TeamSingleMain
                name="Юлія Волощук"
                position="Викладач англійської"
                pearsonImg={teamimg6}
                description="Я Наталя і я все життя мріяла викладати, тому почала цим займатися ще з 5 курсу університету. Працювала 2 місяці у школі, де сама навчалася, тоді й зрозуміла, що це моє. Протягом усіх цих років були учні, які вивчали німецьку та англійську. Моя любов до німецької почалася з першої пісні Rammstein, яку я почула. Брала участь у різних олімпіадах з німецької мови. Англійську вчила з 5 років, мама завжди говорила, що треба вивчати мови. Про мене, як про педагога, можу сказати, що до кожного студента у мене свій власний підхід, який формую перші три заняття. Це залежить від віку, інтересів, мети та характеру, звичайно ж. Навчаюся сама, планую скласти іспит на рівень знання німецької мови, але коли - не скажу. Незабаром сподіваюся. Матеріали використовую різні, аудіо та відео обов'язково! Сленг і вирази, все, чому сама навчилася, розповідаю студентам."
            />
            {/* TeamDetails End */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}


export default TeamSingle;