import React from 'react';
import { Link } from 'react-router-dom';

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Група Pre-Inermediate</h4>
                    <p>Це свого роду «ключовий момент» у житті кожного, хто вивчає англійську мову. Ваш словниковий запас наприкинці курсу складатиме 1500 до 2000 слів та фраз.</p>
                    <h3>Граматика</h3>
                    <ul className="review-list">
                        <li>Present</li>
                        <li>Past and Future Simple</li>
                        <li>Present and Past Continuous</li>
                        <li>Present and Past Perfect</li>
                        <li>Passive Voice</li>
                        <li>Конструкції to be going to, used to do и to be used to</li>
                        <li>Quantifiers</li>
                        <li>Modals (have to/don’t have to, must/musn’t, can/could, may/might, should/shouldn’t)</li>
                        <li>Перший та другий тип умовних речень</li>
                        <li>Прийменники місця та часу</li>
                    </ul>
                    <h3>Лексика</h3>
                    <ul className="review-list">
                        <li>Сім'я та друзі</li>
                        <li>Зовнішність, риси характеру та індивідуальність</li>
                        <li>Робота, професія та робочі місця</li>
                        <li>Заходи</li>
                        <li>Предмети побуту</li>
                        <li>Хобі, відпочинок</li>
                        <li>Звички та спосіб життя</li>
                        <li>Спорт та здоров'я</li>
                        <li>Життя у міських умовах</li>
                        <li>Транспорт</li>
                        <li>Одяг та мода</li>
                        <li>Магазини та покупки</li>
                        <li>Клімат та погода</li>
                        <li>Страхи</li>
                        <li>Тварини</li>
                        <li>Світові новини</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;