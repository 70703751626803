import React from 'react';

import SectionTitle from '../../components/Common/SectionTitle';

// About Image
import AboutImage from '../../assets/img/about/ab1.png';
// import AboutImage from '../../assets/img/about/about2orange.png';

const AboutText = (props) => {

    return (
        <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">            
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                        <div className="img-part">
                            <img className="about-main" src={AboutImage} alt="About Image" />
                        </div>
                    </div>
                    <div className="col-lg-6 pr-70 md-pr-14">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title orange"
                                subtitle="Про WONDERLAND"
                                titleClass="title mb-16"
                                title="Ласкаво просимо до WONDERLAND"
                                descClass="bold-text mb-22"
                                description="Наша школа знаходиться в чудовому місті Одеса, ми працюємо вже понад 3 роки.
                                У нашій школі прекрасна дружня атмосфера та чудова команда педагогів з якими процес навчання буде лише на користь та на радість!"
                                secondDescClass="desc"
                                secondDescription="В даний момент через воєнний стан заняття проходять в онлайн форматі. Ми пропонуємо формат індивідуальних занять, який підійде учням з певним графіком, а також формат групових занять. На даний момент у нас функціонують 7 дорослих та 4 дитячі групи різного рівня."
                            />
                            {/* Section Title End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutText;