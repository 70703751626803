
import { Link } from 'react-router-dom';

const SinglePostSix = (props) => {
    const { blogClass, blogImage, blogTitle, blogAuthor, blogPublishedDate, blogCategory, blogDesc, blogButtonClass, blogButtonText, linkTo } = props;
    return (
        <div className={blogClass ? blogClass : 'blog-item'}>
            <div className="blog-img">
                <Link to={linkTo}>
                    <img
                        src={blogImage}
                        alt={blogTitle}
                    />
                </Link>
            </div>
            <div className="blog-content">
                <h3 className="blog-title">
                    <Link to={linkTo}>
                        {blogTitle ? blogTitle : 'University while the lovely valley team work'}
                    </Link>
                </h3>
                <div className="blog-desc">
                    {blogDesc ? blogDesc : 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam... '}
                </div>
                <div className={blogButtonClass ? blogButtonClass : 'blog-button'}>
                    <Link to={linkTo} className="blog-btn">
                        {blogButtonText ? blogButtonText : 'Читати більше'}
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default SinglePostSix