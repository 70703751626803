import FeatureSingle from '../Feature/FeatureSingle';

// Feature Image
import image1 from '../../assets/img/features/icon/3.png';
import image2 from '../../assets/img/features/icon/2.png';
import image3 from '../../assets/img/features/icon/1.png';

import eng from '../../assets/img/features/icon/eng.svg';
import chin from '../../assets/img/features/icon/china.svg';
import ger from '../../assets/img/features/icon/german.svg';
import pol from '../../assets/img/features/icon/poland.svg';

const FeatureStyle1 = (props) => {
    const languages = [
      {
        img: eng,
        title: "English",
      },
      {
        img: ger,
        title: "German",
      },
      {
        img: pol,
        title: "Polish",
      },
      {
        img: chin,
        title: "Chinese",
      },
    ];

    return (
        <div className="rs-features main-home" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 md-mb-30">
                        <FeatureSingle
                            itemClass="features-wrap"
                            itemImg={image1}
                            languages={languages}
                            // itemTitle="5,320 online courses"
                            // itemDesc="Enjoy a variety of fresh topics"
                        />
                    </div>
                    <div className="col-lg-4 col-md-12 md-mb-30">
                        <FeatureSingle
                            itemClass="features-wrap"
                            itemImg={image2}
                            // itemTitle="IELTS, FCE, CAE, ЗНО, Maritime, Business, Corporate, IT Eng"
                            // itemDesc="Find the right instructor"
                            itemDesc="IELTS, FCE, CAE, ЗНО, Maritime, Business, Corporate, IT Eng"
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <FeatureSingle
                            itemClass="features-wrap"
                            itemImg={image3}
                            // itemTitle="Lifetime access"
                            itemDesc="Br & Am events, Summer camp, Speaking clubs"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeatureStyle1