import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BlogDetails from './BlogDetails';
import SinglePostSidebar from './SinglePostSidebar';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import Newsletter from '../../components/Common/Newsletter';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SearchModal from '../../components/Layout/Header/SearchModal';

// Image
import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/cat_logo.png';
import footerLogo from '../../assets/img/logo/cat_logo.png';

import postImage from '../../assets/img/blog/inner/1.jpg';
import post1Image from '../../assets/img/blog/inner/b2.jpeg'

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/breadcrumbs/5.jpg';

const SinglePostRightSidebar = (props) => {

    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='blog'
                secondParentMenu='blogSingle'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='perfectschool777@gmail.com'
            />

            <SiteBreadcrumb
                pageTitle="Скільки часу потрібно для освоєння одного рівня англійської мови?"
                pageName="Скільки часу потрібно для освоєння одного рівня англійської мови?"
                breadcrumbsImg={bannerbg}
            />

            {/* Blog Details Start */}
            <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 order-last">
                            <div className="widget-area">
                                <SinglePostSidebar />
                            </div>
                        </div>
                        <div className="col-lg-8 pr-50 md-pr-14">
                          <div className="blog-deatails">
                            <div className="bs-img">
                                <img
                                    src={post1Image}
                                    alt="Post Image"
                                />
                            </div>
                            <div className="blog-full">
                                <div className="blog-desc">
                                    <p>
                                      Для початку перерахуємо всі рівні, які існують! Усього існує 6 рівнів володіння англійською: Elementary, Pre-Intermediate, Intermediate, Upper-Intermediate, Advanced, Proficiency. Один рівень англійської в середньому ви можете завершити за 3-4 місяці продуктивної роботи. Вибравши викладача, необхідно виділити 2-3 рази на тиждень для індивідуальних чи групових занять, також вітається додаткове самонавчання у проміжку між заняттями та обов'язкова практика у вигляді домашніх завдань та тестів. Наприклад рівень В1 Intermediate за розрахунками методистів Кембриджу, ви освоїте приблизно за 150-200 годин курсів плюс близько 200-250 годин вдома. Намагайтеся приділяти англійській хоча б 30 хвилин на день, крім уроків з педагогом. Чи можна за 1 рік вивчити англійську мову? Вивчити його протягом року реально, але не досконало. Це пояснюється тим, що там велика кількість слів, граматичних правил і звичайно ж гарна і правильна вимова.
                                    </p>
                                </div>
                            </div>
                        </div>
                            {/* <BlogDetails /> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* Blog Details End */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}


export default SinglePostRightSidebar;



