import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BlogDetails from './BlogDetails';
import SinglePostSidebar from './SinglePostSidebar';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import Newsletter from '../../components/Common/Newsletter';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SearchModal from '../../components/Layout/Header/SearchModal';

// Image
import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/cat_logo.png';
import footerLogo from '../../assets/img/logo/cat_logo.png';

import postImage from '../../assets/img/blog/inner/1.jpg';
import post1Image from '../../assets/img/blog/inner/b1.jpeg'

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/breadcrumbs/5.jpg';

const SinglePostRightSidebar = (props) => {

    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='blog'
                secondParentMenu='blogSingle'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='perfectschool777@gmail.com'
            />

            <SiteBreadcrumb
                pageTitle="Власне, навіщо?"
                pageName="Власне, навіщо?"
                breadcrumbsImg={bannerbg}
            />

            {/* Blog Details Start */}
            <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 order-last">
                            <div className="widget-area">
                                <SinglePostSidebar />
                            </div>
                        </div>
                        <div className="col-lg-8 pr-50 md-pr-14">
                          <div className="blog-deatails">
                            <div className="bs-img">
                                <img
                                    src={post1Image}
                                    alt="Post Image"
                                />
                            </div>
                            <div className="blog-full">
                                <div className="blog-desc">
                                    <p>
                                        У кожного школяра під час навчання рано чи пізно виникає типове запитання: «А як мені знання знадобляться у житті?». Зазвичай подібні думки мучать юні уми на уроках математики та геометрії, але й англійська мова не стала винятком. Так от «Власне навіщо він нам потрібний?» Якщо всі стандартні аргументи, на кшталт «Це міжнародна мова; мандруватимеш і вільно спілкуватимешся» вже не діють, то ось «ТОП 5 ситуацій», де з англійською мовою, життя безумовно заграє новими фарбами!
                                    </p>
                                </div>
                                <div className="blog-desc mb-40">
                                    <p>
                                      1. Серіали/фільми/книги. Думаю, багато хто помічав, що оригінальне озвучення передає зовсім іншу атмосферу. І який би якісний переклад не був, це зовсім інші емоції. Перейнятися справжньою грою акторів, їхньою харизмою, інтонацією можна лише дивлячись фільми та серіали в оригіналі. Те саме стосується і книг. Мовні звороти автора можуть значно відрізнятися з перекладом. Адже автор вкладає свою душу, а перекладач – свою. І часом це дуже відчувається.
                                    </p>
                                </div>
                                <div className="blog-desc mb-40">
                                    <p>
                                      2. "Мемчики" в інтернеті. Наші вітчизняні жарти не завжди вражають особливим гумором і смаком, чи то справа закордонні. Амерський та британський гумор дуже тонкий, і якщо його зрозуміти та оцінити, то можна отримати неймовірний кайф!
                                    </p>
                                </div>
                                <div className="blog-desc mb-40">
                                    <p>
                                      3. Комунікація. Але не тільки в момент мандрівок, а прямо з дому. У наш час сучасних технологій можна знайти цікавого співрозмовника у будь-якій точці світу. Отримати величезне коло нових та корисних знайомств. А може навіть знайти кохання ;)
                                    </p>
                                </div>
                                <div className="blog-desc mb-40">
                                    <p>
                                      4. Ерудиція. Дуже добре, що зараз а моді ПП, ЗОЖ та знання! У наш час складно здивувати крутим «цибулею» або змінами на вигляд. Це є практично для кожного. А ось начитані та освічені люди викликають набагато більший інтерес!
                                    </p>
                                </div>
                                <div className="blog-desc mb-40">
                                    <p>
                                      5. Іноземні сайти. Повертаємось до пункту про «мемчики». Найчастіше при пошуку інформації в будь-якій сфері Google видає сайти «.сом». Що не дивно. Америка знаходиться на піку розвитку технологій, і всі найсвіжіші новини, дослідження найчастіше можна знайти саме там. Але не завжди статті перекладають. І визнання мови, ваші можливості пошуку якісної та свіжої інформації збільшуються у рази!
                                    </p>
                                </div>
                                {/* <div className="blog-desc">
                                    <p>
                                        We denounce with righteous indige nation and dislike men who are so beguiled and demo realized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided.
                                    </p>
                                </div>
                                <blockquote><p>Globally incubate standards compliant channels before scalable benefits. Quickly disseminate superior deliverables whereas web-enabled applications.</p></blockquote>
                                <div className="blog-desc mb-40">
                                    <p>
                                        Interactively procrastinate high-payoff content without backward-compatible data. Quickly cultivate optimal processes and tactical architectures. Completely iterate covalent strategic theme areas via accurate e-markets. Globally incubate standards compliant channels before scalable benefits.
                                    </p>
                                </div>
                                <h2 className="title mb-40">Economy may face double recession</h2>
                                <div className="blog-desc mb-34">
                                    <p>
                                        Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment. Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation X is on the runway heading towards a streamlined cloud solution. User generated content in real-time will have multiple touchpoints for offshoring.
                                    </p>
                                </div>
                                <div className="blog-img mb-40">
                                    <img
                                        src={innerImage}
                                        alt=""
                                    />
                                </div>
                                <div className="blog-desc mb-40">
                                    <p>
                                        Phosfluorescently engage worldwide methodologies with web-enabled technology. Interactively coordinate proactive e-commerce via process-centric “outside the box” thinking. Completely pursue scalable customer service through sustainable potentialities. Collaboratively administrate turnkey channels whereas virtual e-tailers. Objectively seize scalable metrics whereas proactive e-services. Seamlessly empower fully researched growth strategies and interoperable internal or “organic” sources.
                                    </p>
                                </div>
                                <ul className="unorder-list mb-20">
                                    <li>New Construction Benefit of Service</li>
                                    <li>Renovations Benefit of Service</li>
                                    <li>Historic Renovations and Restorations</li>
                                    <li>Additions Benefit of Service</li>
                                    <li>Rebuilding from fire or water damage</li>
                                </ul>
                                <h2 className="title">Experts Always Ready to Maximizing Products</h2>
                                <div className="blog-desc">
                                    <p>
                                        Proactively fabricate one-to-one materials via effective e-business. Completely synergize scalable e-commerce rather than high standards in e-services. Assertively iterate resource maximizing products after leading-edge intellectual capital. Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.
                                    </p>
                                </div>
                                <h4 className="sm-title">Experts Always Ready to Maximizing Products</h4>
                                <div className="blog-desc">
                                    <p>
                                        Interactively procrastinate high-payoff content without backward-compatible data. Quickly cultivate optimal processes and tactical architectures. Completely iterate covalent strategic theme areas via accurate e-markets. Globally incubate standards compliant channels before scalable benefits. Quickly disseminate superior deliverables whereas web-enabled applications. Quickly drive clicks-and-mortar catalysts for change before vertical architectures.
                                    </p>
                                </div> */}
                            </div>
                        </div>
                            {/* <BlogDetails /> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* Blog Details End */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}


export default SinglePostRightSidebar;



