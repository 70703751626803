import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BlogDetails from './BlogDetails';
import SinglePostSidebar from './SinglePostSidebar';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import Newsletter from '../../components/Common/Newsletter';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SearchModal from '../../components/Layout/Header/SearchModal';

// Image
import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/cat_logo.png';
import footerLogo from '../../assets/img/logo/cat_logo.png';

import postImage from '../../assets/img/blog/inner/1.jpg';
import post1Image from '../../assets/img/blog/inner/b8.jpeg'

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/breadcrumbs/5.jpg';

const SinglePostRightSidebar = (props) => {

    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='blog'
                secondParentMenu='blogSingle'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='perfectschool777@gmail.com'
            />

            <SiteBreadcrumb
                pageTitle="Zoom, Skype, Viber, Hangouts... Страшно, так?"
                pageName="Zoom, Skype, Viber, Hangouts... Страшно, так?"
                breadcrumbsImg={bannerbg}
            />

            {/* Blog Details Start */}
            <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 order-last">
                            <div className="widget-area">
                                <SinglePostSidebar />
                            </div>
                        </div>
                        <div className="col-lg-8 pr-50 md-pr-14">
                          <div className="blog-deatails">
                            <div className="bs-img">
                                <img
                                    src={post1Image}
                                    alt="Post Image"
                                />
                            </div>
                            <div className="blog-full">
                                <div className="blog-desc">
                                    <p>
                                      Дистанційне навчання розділило своїх учасників на два великі та активно протиборчі табори. Одні кричать, що це прямий шлях до деградації, діти нічого не вчаться, все погано, Наташа, ми все впустили
                                      Інші говорять про те, що це набагато комфортніше і спокійніше, знижується рівень стресу (і відповідальності (с) 1 група), нікуди не треба йти, їхати, навіть одягнути потрібно тільки половину тулуба:) Що ж, маючи за плечима досвід онлайн-викладання Ще до того, як це стало мейнстримом, можу сказати, що плюси і переваги тут однозначно є.
                                    </p>
                                </div>
                                <div className="blog-desc">
                                    <p>
                                      1) Різноманітність освітніх інструментів. Не у всіх школах і не у всіх класах є мультимедійна дошка, ноутбук та навіть телевізор. Тому незаперечною перевагою онлайн навчання є можливість включити будь-яке відео, аудіо чи гру для всіх учнів одночасно. Головне, не заблукати в тоннах інформації
                                    </p>
                                </div>
                                <div className="blog-desc">
                                    <p>
                                      2) Доступність. Інтернет є у всіх. Ладно, майже у всіх) Сьогодні всі необхідні програми та програми для онлайн навчання з легкістю можна встановити на телефон, і займатися будь-де. На жаль, в області та районних центрах справи з якістю інтернет-з'єднання набагато гірші, ніж у великих населених пунктах, але давайте вірити в краще.
                                    </p>
                                </div>
                                <div className="blog-desc">
                                    <p>
                                      3) Комфорт. Вам дійсно не потрібно нікуди їхати, носити на собі тонну підручників, роздруківок, файлів, карток і т.д. Ви надягаєте блузку поверх піжами і починаєте процес освіти
                                    </p>
                                </div>
                                <div className="blog-desc">
                                    <p>
                                      4) Тайм-менеджмент. Дуже сучасне слово, за яким ховається цінність часу, як єдиного непоправного ресурсу. Дистанційне навчання може як розслабити, так і привчити до чіткої організації свого робочого дня. Все залежить від підходу.
                                    </p>
                                </div>
                                <div className="blog-desc">
                                    <p>
                                      5) Найголовніший плюс – пошук способів подачі навчального матеріалу, удосконалення методик, розвиток креативності та навичок роботи з програмами, можливість створення власного інфопродукту. А ще викладачі можуть ділитися своїми розробками, користуватися матеріалами колег, обговорювати останні новини у сфері освіти в режимі живого часу без нудних педрад та протоколів. Підсумовуючи, можу сказати, що майбутнє викладання однозначно багато в чому буде пов'язане з онлайн навчанням та його різновидами. Але не варто забувати, що найчастіше нове-це добре забуте старе, і традиційні методики можна зробити цікавими та сучасними, приклавши щіпку творчості та праці. Per aspera ad astra, дорогі колеги та учні!
                                    </p>
                                </div>
                                <div className="blog-desc">
                                    <p>
                                      Per aspera ad astra, дорогі колеги та учні!
                                    </p>
                                </div>
                            </div>
                        </div>
                            {/* <BlogDetails /> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* Blog Details End */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}


export default SinglePostRightSidebar;



