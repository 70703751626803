import React from 'react';
import { Helmet } from 'react-helmet';
import SingleTeam from '../../components/Team/SingleTeam'
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Newsletter from '../../components/Common/Newsletter';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';

// Image
import Logo from '../../assets/img/logo/cat_logo.png';
import footerLogo from '../../assets/img/logo/cat_logo.png';

import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

// Team Members
import teamimg1 from '../../assets/img/cta/Angie.png';
// import teamimg1 from '../../assets/img/team/1.jpg';
import teamimg2 from '../../assets/img/team/Sofiia.jpg';
// import teamimg2 from '../../assets/img/team/2.jpg';
import teamimg3 from '../../assets/img/team/Natalia.jpeg';
// import teamimg3 from '../../assets/img/team/3.jpg';
import teamimg4 from '../../assets/img/team/4.jpg';
import teamimg5 from '../../assets/img/team/Margarita.jpeg';
// import teamimg5 from '../../assets/img/team/5.jpg';
import teamimg6 from '../../assets/img/team/JuliaV.jpg';
// import teamimg6 from '../../assets/img/team/6.jpg';
import teamimg7 from '../../assets/img/team/7.jpg';

const Team = () => {

    return (
        <React.Fragment>
            <OffWrap />
            <Helmet>
              <title>"Наша Wonder команда"</title>
              <meta name="description" content="Приєднуйтеся до Школи Іноземних Мов Wonderland і навчайтеся іноземним мовам з нами. Ми пропонуємо широкий вибір курсів іноземних мов, що допоможуть вам розвивати свої навички у вивченні іноземних мов. З нами ви зможете швидко і зручно навчитися іноземній мові, що відкриє для вас нові можливості у житті і кар'єрі. Зареєструйтеся зараз і розпочніть свій шлях до володіння іноземною мовою з нами!" />
              <meta property="og:title" content="Наша Wonder команда" />
              <meta property="og:image" content="/preview.jpg" />
            </Helmet>
            <Header
                parentMenu='pages'
                secondParentMenu='team'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='perfectschool777@gmail.com'
                Location='374 William S Canning Blvd, MA 2721, USA '
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Команда"
                pageName="Команда"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            <div className="rs-team style1 orange-style pt-100 pb-50 md-pt-80 md-pb-30 white-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-50">
                          <SingleTeam
                              itemClass="team-item"
                              Image={teamimg1}
                              linkTo="/team/angelika-hibska"
                              Title="Анжеліка Гібська"
                              Designation="Засновниця та викладач"
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                          <SingleTeam
                              teamClass="team-item"
                              Image={teamimg2}
                              linkTo="/team/sofiia-sokolnikova"
                              Title="Софія Сокольнікова"
                              Designation="Викладач англійської"
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                          <SingleTeam
                              teamClass="team-item"
                              linkTo="/team/natalia-masaliutina"
                              Image={teamimg3}
                              Title="Наталя Масалютіна"
                              Designation="Викладач англійської та німецької"
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                          <SingleTeam
                              teamClass="team-item"
                              linkTo="/team/julia-cimbala"
                              Image={teamimg3}
                              Title="Юлія Цимбала"
                              Designation="Викладач англійської"
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                          <SingleTeam
                              teamClass="team-item"
                              linkTo="/team/margarita-gordeeva"
                              Image={teamimg5}
                              Title="Маргарита Гордєєва"
                              Designation="Викладач англійської"
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                          <SingleTeam
                              teamClass="team-item"
                              Image={teamimg6}
                              linkTo="/team/julia-voloschuk"
                              Title="Юлія Волощук"
                              Designation="Викладач китайської"
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-50">
                          <SingleTeam
                              teamClass="team-item"
                              Image={teamimg7}
                              linkTo="/team/anastasiia-noskova"
                              Title="Анастасія Носкова"
                              Designation="Адміністратор"
                          />
                        </div>
                    </div>
                </div>
            </div>

            <Newsletter
                sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
                titleClass="title mb-0 white-color"
            />

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}

export default Team