import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';

// Image
import videoImg from '../../../assets/img/about/about-video-bg2.png';

const CourseSidebar = (props) => {
  const { coursePrice } = props;

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="inner-column">
            <div className="course-features-info">
                <ul>
                    <li className="lectures-feature">
                        <i className="fa fa-files-o"></i>
                        <span className="label">Кількість годин</span>
                        <span className="value">84</span>
                    </li>
                    <li className="duration-feature">
                        <i className="fa fa-clock-o"></i>
                        <span className="label">Тривалість</span>
                        <span className="value">7 місяців</span>
                    </li>
                </ul>
            </div>                
            <div className="btn-part">
                <h3 class="card-title pricing-card-title text-center pb-24">3100 грн. <small class="text-muted">/ місяць</small></h3>
                <a href="https://www.instagram.com/wonderland_eng/?igshid=YmMyMTA2M2Y%3D" target="_blank" className="btn readon2 orange-transparent">Записатись</a>
            </div>
        </div>
    );
}

export default CourseSidebar;