import React from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import Newsletter from '../../components/Common/Newsletter';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import TeamSingleMain from './TeamSingleMain';

// Image
import Logo from '../../assets/img/logo/cat_logo.png';
import footerLogo from '../../assets/img/logo/cat_logo.png';

import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

import teamimg3 from '../../assets/img/team/Natalia.jpeg';

const TeamSingle = () => {
    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='pages'
                secondParentMenu='team'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='perfectschool777@gmail.com'
                // Location='374 William S Canning Blvd, MA 2721, USA'
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Юлія Цимбала"
                pageName="Команда"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            {/* TeamDetails Start */}
            <TeamSingleMain
                name="Юлія Цимбала"
                position="Викладач англійської"
                pearsonImg={teamimg3}
                description={`Закінчила університет ім. Мечникова (факультет РГФ) за спеціальністю філолог, викладач англійської та іспанської мов та літератури.

                  Проходила стажування у мовній школі Kaplan International Colleges (LA, USA). Досвід роботи перекладачем 7 років, викладачем – 12 років.
                  
                  Юлія вітає нестандартні методи вивчення мови, а також віддає перевагу сучасним і захоплюючим джерелам, наприклад - розбір серіалів і новин за рівнями. Вона завжди знає тренди та новинки в аспектах навчання.
                  
                  Поціновителька Американської вимови, а також найзайнятіший вчитель у Wonderland. Студенти не поспішають пропускати її заняття, оскільки знають, кожен урок буде наповнений енергійним, і головне потрібним матеріалом.`}
            />
            {/* TeamDetails End */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}


export default TeamSingle;