import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

const FaqSection = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="rs-faq-part style1 orange-style pt-100 pb-100 md-pt-70 md-pb-70">
            <ModalVideo channel='youtube' isOpen={isOpen} videoId='FT1i0MSJB9A' onClose={() => { openModal(); }} />
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 padding-0">
                        <div className="main-part">
                            <div className="title mb-40 md-mb-14">
                                <h2 className="text-part">Часто задавані питання</h2>
                            </div>
                            <div className="faq-content">
                                <Accordion className="accordion-style1" preExpanded={'a'}>
                                    <AccordionItem className="accordion-item" uuid="a">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="card-link">
                                                Чи можливо приєднатись не з початку курсу?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                            Так, авжеж. Після тестування на рівень володіння мовою викладач порадить вам відповідну вагому рівню групу і безкоштовне пробне заняття.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="b">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="card-link">
                                                Який мінімальний вік для початку навчання?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                            6 років. Долучитися до літнього табору можливо з 5 років.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="c">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="card-link">
                                                Що потрібно для занять?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                            Стабільний інтернет, гарний настрій та яскравий зошит.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="d">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="card-link">
                                                Скільки потрібно часу на опанування одного рівня?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                            Залежить від вашої мотивації. В середньому 5-8 місяців.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 padding-0">
                        <div className="img-part media-icon orange-color">
                            <a href="#" className="popup-videos" onClick={() => { openModal(); }}><i className="fa fa-play"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FaqSection;