import React from 'react';
import CourseSingleFour from '../../components/Courses/CourseSingleFour';

// Courses Image
import image1 from '../../assets/img/courses/4.jpg';
import image2 from '../../assets/img/courses/5.jpg';
import image3 from '../../assets/img/courses/6.jpg';
import image4 from '../../assets/img/courses/7.jpg';
import image5 from '../../assets/img/courses/8.jpg';
import image6 from '../../assets/img/courses/9.jpg';

const Courses = () => {

    return (
        <React.Fragment>
            <div className="rs-popular-courses style3 orange-style pt-100 pb-100 md-pt-70 md-pb-80">
                <div className="container">
                    <div className="row d-flex align-items-stretch">
                        <div className="d-flex align-items-stretch col-lg-4 col-md-6 col-sm-6 mb-40">
                            <CourseSingleFour
                                courseClass="courses-item w-100 d-flex flex-column"
                                courseImg={image1}
                                courseCategory="Англійська"
                                courseTitle="Група з нуля"
                                coursePrice="2700 грн."
                                btnText="Переглянути"
                                courseLink="/course/from-zero"
                            />
                        </div>
                        <div className="d-flex align-items-stretch col-lg-4 col-md-6 col-sm-6 mb-40">
                            <CourseSingleFour
                                courseClass="courses-item w-100 d-flex flex-column"
                                courseImg={image2}
                                courseCategory="Англійська"
                                courseTitle="Група Pre-Intermediate"
                                coursePrice="3100 грн."
                                btnText="Переглянути"
                                courseLink="/course/pre-intermediate"
                            />
                        </div>
                        <div className="d-flex align-items-stretch col-lg-4 col-md-6 col-sm-6 mb-40">
                            <CourseSingleFour
                                courseClass="courses-item w-100 d-flex flex-column"
                                courseImg={image2}
                                courseCategory="Англійська"
                                courseTitle="Група Intermediate"
                                coursePrice="3500 грн."
                                btnText="Переглянути"
                                courseLink="/course/intermediate"
                            />
                        </div>
                        <div className="d-flex align-items-stretch col-lg-4 col-md-6 col-sm-6 mb-40">
                            <CourseSingleFour
                                courseClass="courses-item w-100 d-flex flex-column"
                                courseImg={image3}
                                courseCategory="Англійська"
                                courseTitle="Група Upper-Intermediate"
                                coursePrice="4000 грн."
                                btnText="Apply Now"
                                courseLink="/course/upper-intermediate"
                            />
                        </div>
                        <div className="d-flex align-items-stretch col-lg-4 col-md-6 col-sm-6 mb-40">
                            <CourseSingleFour
                                courseClass="courses-item w-100 d-flex flex-column"
                                courseImg={image4}
                                courseCategory="Англійська"
                                courseTitle="Група Advanced"
                                coursePrice="4500 грн."
                                btnText="Переглянути"
                                courseLink="/course/advanced"
                            />
                        </div>
                        <div className="d-flex align-items-stretch col-lg-4 col-md-6 col-sm-6 mb-40">
                            <CourseSingleFour
                                courseClass="courses-item w-100 d-flex flex-column"
                                courseImg={image4}
                                courseCategory="Англійська для дітей"
                                courseTitle="Група Starters 1"
                                coursePrice="1500 грн."
                                btnText="Переглянути"
                                courseLink="/course/starters1"
                            />
                        </div>
                        <div className="d-flex align-items-stretch col-lg-4 col-md-6 col-sm-6 mb-40">
                            <CourseSingleFour
                                courseClass="courses-item w-100 d-flex flex-column"
                                courseImg={image6}
                                courseCategory="Англійська для дітей"
                                courseTitle="Група Starters 2 (продовжуючі)"
                                coursePrice="1500 грн."
                                btnText="Переглянути"
                                courseLink="/course/starters2"
                            />
                        </div>
                        <div className="d-flex align-items-stretch col-lg-4 col-md-6 col-sm-6 mb-40">
                            <CourseSingleFour
                                courseClass="courses-item w-100 d-flex flex-column"
                                courseImg={image6}
                                courseCategory="Англійська для дітей"
                                courseTitle="Група Flyers"
                                coursePrice="1800 грн."
                                btnText="Переглянути"
                                courseLink="/course/flyers"
                            />
                        </div>
                        <div className="d-flex align-items-stretch col-lg-4 col-md-6 col-sm-6 mb-40">
                            <CourseSingleFour
                                courseClass="courses-item w-100 d-flex flex-column"
                                courseImg={image6}
                                courseCategory="Англійська для дітей"
                                courseTitle="Група Movers"
                                coursePrice="2000 грн."
                                btnText="Переглянути"
                                courseLink="/course/movers"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Courses