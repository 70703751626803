import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BlogDetails from './BlogDetails';
import SinglePostSidebar from './SinglePostSidebar';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import Newsletter from '../../components/Common/Newsletter';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SearchModal from '../../components/Layout/Header/SearchModal';

// Image
import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/cat_logo.png';
import footerLogo from '../../assets/img/logo/cat_logo.png';

import postImage from '../../assets/img/blog/inner/1.jpg';
import post1Image from '../../assets/img/blog/inner/b3.jpeg'

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/breadcrumbs/5.jpg';

const SinglePostRightSidebar = (props) => {

    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='blog'
                secondParentMenu='blogSingle'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='perfectschool777@gmail.com'
            />

            <SiteBreadcrumb
                pageTitle="Як вчити людей старших за тебе?"
                pageName="Як вчити людей старших за тебе?"
                breadcrumbsImg={bannerbg}
            />

            {/* Blog Details Start */}
            <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 order-last">
                            <div className="widget-area">
                                <SinglePostSidebar />
                            </div>
                        </div>
                        <div className="col-lg-8 pr-50 md-pr-14">
                          <div className="blog-deatails">
                            <div className="bs-img">
                                <img
                                    src={post1Image}
                                    alt="Post Image"
                                />
                            </div>
                            <div className="blog-full">
                                <div className="blog-desc">
                                    <p>
                                      Перед тим, як відповісти на це питання, для початку варто зрозуміти, про що він. «Як» не одно «яке», бо другий варіант більше відноситься до конкретної людини; його можна було б прирівняти до питання "як справи?". Так от, у мене справи чудово, і вчити людей чогось нового, незалежно від їхнього віку, я теж вважаю прекрасним. Звичайно, є нюанси, але вони вже стосуються початкової теми «як?». 
                                      Думаю, відповідь передбачає якусь чітку інструкцію щодо засобів та методики навчання. Але як би мені не хотілося ставати на противагу великим вченим та методистам, які все своє життя присвячують виведенню ідеальної формули формування знань у головах людства, у свої 20 років я можу голосно заявити, що такої формули НЕ існує!
                                    </p>
                                </div>
                                <div className="blog-desc">
                                    <p>
                                      Хто я така? Звідки в мене такі великі знання і така впевненість у собі? Насправді, відповідь досить банальна: я просто вчитель, до якого і 5-річна дитина і 35-річний чоловік поспішають на урок, боятися не виконати домашнє завдання, а після його закінчення, з усмішкою на обличчі, прагнуть блиснути перед батьками або своїми друзями маленькою блискіткою нових знань та вражень. Чи це не ціль?
                                    </p>
                                </div>
                                <div className="blog-desc">
                                    <p>
                                      Якщо діти і дорослі такі схожі, то навіщо ставити зайві питання?
                                      Як учитель, я не бачу різниці у віці. Єдиною відзнакою є мотивація людей старшого покоління, вони чітко дають собі звіт, навіщо вони прийшли на заняття, за що платять гроші і часом вони готові витягнути з тебе максимум інформації, після чого ти будеш як найсоковитіший лимон, вичавлений до останньої крапельки. . І, повірте, це втомлює не менше, ніж заняття з найбільш гіперактивною дитиною у світі!
                                      Говорячи про засоби та методики навчання не можна вибрати щось одне. Благо, ми живемо в 21-му столітті і у кожного є необмежений доступ до різних матеріалів: книги, підручники, зошити, додаткові сайти, навчальні ігри, фільми, аудіо, музика та багато іншого. Особисто я люблю поєднувати різні види діяльності на уроці, а найголовніше, не варто забувати, що всередині кожного живе бешкетна дитина і надаватися веселим навчальним іграм люблять навіть дорослі!
                                    </p>
                                </div>
                                <div className="blog-desc">
                                    <p>Висновок такий: якщо людина бачить, що ти здатна її чомусь навчити і дати їй те, навіщо вона прийшла, то після першого ж заняття, всі сумніви та жарти про вік йдуть убік. Тебе огортає впевненість і бажання отримати гідний фідбек, і навіть у 60 з хвостиком років, людина заглядає тобі в рот із жадобою нових знань і виявляє свою повагу навіть до такого зеленого огірочка як Я, адже перед ним стоїть гуру своєї справи! :)
                                    </p>
                                </div>
                            </div>
                        </div>
                            {/* <BlogDetails /> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* Blog Details End */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}


export default SinglePostRightSidebar;



