import React from 'react';
import { Link } from 'react-router-dom';

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Група Movers</h4>
                    <p>Група для підлітків 12-15 років. Навчальний посібник Go getter 4.</p>
                    <p>Вивчаються основі часи англійської мови: Present simple, Present continuos, Past simple,Past continuous, Present perfect, going to and will, first conditional та Passive voice. Навчання відбувається за допомогою посібників та онлайн -платформи з додатковими завданнями.</p>
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;