import React from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import Newsletter from '../../components/Common/Newsletter';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import TeamSingleMain from './TeamSingleMain';

// Image
import Logo from '../../assets/img/logo/cat_logo.png';
import footerLogo from '../../assets/img/logo/cat_logo.png';

import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

import teamimg2 from '../../assets/img/team/Sofiia.jpg';

const TeamSingle = () => {
    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='pages'
                secondParentMenu='team'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='perfectschool777@gmail.com'
                // Location='374 William S Canning Blvd, MA 2721, USA'
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Софія Сокольнікова"
                pageName="Команда"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            {/* TeamDetails Start */}
            <TeamSingleMain
                name="Софія Сокольнікова"
                position="Викладач англійської"
                pearsonImg={teamimg2}
                description="Мене звуть Софія і я обожнюю свою роботу за те, що можна ніколи не вимикати дитинство, не відкладати його на потім і не бути надто серйозною, правильною вчителькою. Я працюю з учнями зовсім різного віку, від 5 до 55 років. Але найбільше я люблю уроки саме з дітьми та підлітками, бо це завжди весело. На заняттях ми граємо, співаємо, бігаємо класом, робимо різні цікавості власними руками, шукаємо скарби, читаємо, вчимо вірші, дивимося відео з тиктоку. Іноді, звичайно, пишемо:) Діти мають змогу самостійно обирати види активностей на уроці чи порядок їх виконання. Кожен мій студент є унікальним і вимагає індивідуального підходу, і щоразу це викликає інтерес для вчителя. Дуже часто я можу прийти до тями близько півночі, шукаючи якусь нову движку, вірша і вправу не про переписування тексту. Але це і є велике щастя - робити те, що любиш і любити те, що робиш!"
            />
            {/* TeamDetails End */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}


export default TeamSingle;