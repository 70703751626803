import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Image
import aboutImg from '../../assets/img/about/history.png';
// import tabImg1 from '../../assets/img/about/tab1.jpg';
import tabImg1 from '../../assets/img/about/a1.png';
import tabImg2 from '../../assets/img/about/tab2.jpg';
import tabImg3 from '../../assets/img/about/tab3.jpg';

const AboutTab = () => {

    let tab1 = "Історія",
        tab2 = "Міссія та концепція",
        tab3 = "Адміністрація"
    const tabStyle = 'nav nav-tabs histort-part';

    return (
        <div className="rs-about style1 pt-100 pb-100 md-pt-70 md-pb-70">
            <div className="container">
                <Tabs>
                    <div className="row align-items-center">
                        <div className="col-lg-6 padding-0 md-pl-14 md-pr-14 md-mb-30 relative">
                            <div className="img-part">
                                <img 
                                    src={aboutImg}
                                    alt="About Image" 
                                />
                            </div>
                            <TabList className={tabStyle}>
                                <Tab>
                                    <button><span className="icon-part"><i className="flaticon-banknote"></i></span>{tab1}</button>
                                </Tab>
                                <Tab>
                                    <button><span className="icon-part"><i className="flaticon-flower"></i></span>{tab2}</button>
                                </Tab>
                                <Tab>
                                    <button><span className="icon-part"><i className="flaticon-analysis"></i></span>{tab3}</button>
                                </Tab>
                            </TabList>
                        </div>
                        <div className="col-lg-6 pl-120 md-pl-14">
                            <TabPanel>
                                <div className="about-history">
                                    <div className="sec-title mb-24">
                                        <h2 className="title">Історія</h2>
                                        <div className="desc">
                                          Все почалося в 2019 році, коли наш керівник Анжеліка Гібська вирішила створити дещо неймовірне - простір, в якому поряд з лунанням іноземних мов, ви станете свідками справжніх див, щирої любові до справи, а також найяскравіших уроків. Поєднавши все це, ми додали ще пристрасті до розвитку, що і утворило школу WONDERLAND.
                                        </div>
                                    </div>
                                    <div className="tab-img">
                                        <img 
                                            src={tabImg1}
                                            alt="Tab Image" 
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="about-mission">
                                    <div className="sec-title mb-24">
                                        <h2 className="title">Міссія та концепція</h2>
                                        <div className="desc">
                                          Концепцією нашого начально закладу вважаємо:<br/>
                                          - Немає неталановитих студентів, є нерозкриті та недомотивовані.<br/>
                                          - Четвертий рік поспіль доводимо що в нас можна начватись всією родиною.<br/>
                                          - Пірнувши в внашу атмосферу одного разу, ви будете не в змозі відмовитись від нас, бо WONDERLAND це кохання до іноземних мов з першого уроку.
                                        </div>
                                    </div>
                                    <div className="tab-img">
                                        <img 
                                            src={tabImg2}
                                            alt="Tab Image" 
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="about-admin">
                                    <div className="sec-title mb-24">
                                        <h2 className="title">Адміністрація</h2>
                                        <div className="desc">
                                          Наш любʼязний адміністратор Анастасія обовʼязково надасть компетенту відповідь на будь-яке питання стовоно навчального процесу, а також сформує комфортний графік ваших занять.
                                        </div>
                                    </div>
                                    <div className="tab-img">
                                        <img 
                                            src={tabImg3}
                                            alt="Tab Image" 
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </Tabs>
            </div>
        </div>
    );
}

export default AboutTab;