import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';

const Cta = () => {

    return (
        <div className="rs-cta main-home">
            <div className="partition-bg-wrap">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-6 col-lg-6 pl-70 md-pl-14">
                            <SectionTitle
                                sectionClass="sec-title3 mb-40"
                                titleClass="title white-color mb-16"
                                title="Анжеліка Гібська"
                                descClass="desc white-color pr-100 md-pr-0"
                                description="Знайомтесь, наш керівник, ідейний натхненник та викладач із 13-річним досвідом. Своїм навчальним девізом вважає: немає неталановитих студентів, є нерозкриті."
                            />
                            <div className="btn-part">
                                <Link className="readon orange-btn transparent" target="_blank" to={{pathname: 'https://instagram.com/wonderland_eng?igshid=YmMyMTA2M2Y='}}>Записатись на курс</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cta;