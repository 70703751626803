import React from 'react';
import { Link } from 'react-router-dom';

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Група Upper-Inermediate</h4>
                    <p>Upper-Intermediate перекладається як «вище середнього» і позначається маркуванням B2 за загальноєвропейською системою CEFR.</p>
                    <p>Фраза «вища за середній» може не викликати wow-ефект, хоча насправді Upper-Intermediate Level дає великі можливості: скласти тест TOEFL або IELTS, навчатися в закордонному університеті, вільно спілкуватися практично на будь-яку тему, працювати в міжнародній компанії.</p>
                    <h3>Лексика</h3>
                    <ul className="review-list">
                        <li>Враження та емоції</li>
                        <li>Види комунікації</li>
                        <li>Бажання та мрії</li>
                        <li>Кругообіг життя, злети та падіння</li>
                        <li>Дивовижні люди</li>
                        <li>Дружба та найкращі друзі</li>
                        <li>Робота</li>
                        <li>Амбіції та досягнення цілей</li>
                        <li>Гроші та бізнес</li>
                        <li>Правила поведінки та гарні манери</li>
                        <li>Здоровий спосіб життя та залежності</li>
                        <li>Розповіді та історії</li>
                        <li>Нещасні випадки та негаразди</li>
                        <li>Краса</li>
                        <li>Таємниці та загадки</li>
                        <li>Прогрес та розвиток</li>
                        <li>Гастрономічний рай</li>
                        <li>Будинок милий будинок або спрага пригод</li>
                        <li>Спогади</li>
                        <li>Способи самовираження</li>
                        <li>Рух це життя</li>
                        <li>Правда чи вигадка</li>
                    </ul>
                    <h3>Граматика</h3>
                    <ul className="review-list">
                        <li>Тимчасові форми активної та пасивної застави (active/passive voice)</li>
                        <li>Present Perfect Continuous (have been doing) та Past Perfect Continuous (had been doing) </li>
                    </ul>
                    <h3>Звороти</h3>
                    <ul className="review-list">
                        <li>Used to / get used to / be used to / would</li>
                        <li>Різниця показників майбутнього часу: will/may/might/to be going to/Present</li>
                        <li>Continuous/Present Simple</li>
                        <li>Future Perfect (will have done) та Future Perfect Continuous (will have been doing)</li>
                        <li>I wish / If only / I prefer / I'd prefer / I'd rather</li>
                        <li>Зворот мови Complex Object (I want you to do..)</li>
                        <li>Модальні дієслова та їх еквіваленти: can / could / should / must / may / might / will / shall / be able to / dare do / ought to / have to / be allowed to</li>
                        <li>Модальні дієслова минулого часу</li>
                        <li>Вживання іменників з визначальними словами</li>
                        <li>Порівняльні конструкції</li>
                        <li>Узгодження часів</li>
                        <li>Непряма мова (he said that..)</li>
                    </ul>
                    <h3>Словотвір</h3>
                    <ul className="review-list">
                        <li>Суфікси та приставки</li>
                        <li>Усі види питань</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;