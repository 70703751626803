import React from 'react';
import { Link } from 'react-router-dom';

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Група Starters 2 (продовжуючі)</h4>
                    <p>Група для дітей 8-9(10) років,які продовжують вивчення англійської мови.</p>
                    <p>Ця група завершує підручник English world 1 в першому семестрі і переходить до English world 2,де починають читати більші за обсягом та лексичним наповненням тексти, аналізувати їх, знайомляться з поняттям минулого часу та теперішнього часу, прислівниками частоти, визначенням часу за годинником.</p>
                    <p>Підручник розрахований на два роки навчання.</p>
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;